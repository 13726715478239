/*
 * @Author: your name
 * @Date: 2020-12-17 09:14:10
 * @LastEditTime: 2020-12-17 16:13:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nethallofficialaccounts\src\views\WaterAccount\module\chartConfig.js
 */
export const chartOption = {
  grid: {
    top: 30,
    bottom: '4%',
    left: '2%',
    right: '4%',
    containLabel: true
  },
  title: {
    // 标题
    text: '用水量：吨',
    textStyle: {
      fontFamily: 'PingFangSC-Regular',
      fontSize: '12',
      color: 'rgba(0,0,0,0.70)'
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: '#ffffff',
    extraCssText: 'box-shadow: 0 2px 8px 0 #D2E7FF;',
    axisPointer: {
      type: 'cross',
      animation: false,
      label: {  show: false }
    },
    textStyle: {
      color: 'rgba(146,146,146)',
      fontSize: 12
    }
  },
  dataset: {
    source: []
  },
  color: [
    '#F5A623',
    '#7ED321',
    '#4A90E2',
    '#8A86F6',
    '#00CA92',
    '#F1546B',
    '#3527C4',
    '#7297F2',
    '#94CEFD',
    '#FF6838',
    '#EBB7FF',
    '#AECD70',
    '#AD5794',
    '#588BFF'
  ],
  legend: {
    data: [],
    z: 2
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: 'rgba(146,146,146)' // 坐标轴、字体颜色
        }
      },
      axisLabel: {
        rotate: 45,
      }
    }
  ],
  yAxis: [
    {
      name: '',
      type: 'value',
      minInterval: 0.2,
      axisLine: {
        show: false, // 坐标轴是否显示
        lineStyle: {
          color: 'rgba(146,146,146)' // 坐标轴、字体颜色
        }
      },
      splitLine: {
        lineStyle: { color: ['rgba(135,150,176,0.2)'], type: 'dashed' } //坐标轴网格样式
      },
      axisTick: {
        show: false // 刻度线是否显示
      }
    }
  ],
  series: []
};
